import React from "react"

import GlitchText from 'react-glitch-effect/core/GlitchText'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '500'
  },
  description: {
    '&::selection': {
      backgroundColor: theme.palette.secondary.light
    },
    fontWeight: '300'
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '20px'
  },
  image: {
    height: 'auto',
    width: '100%',
  }
}));

const Picture = ({data}) => {
  const classes = useStyles();
  return (
    <>
      <img src={data[0].src}
        className={classes.image}
      />
    </>
  )
} 

const Description = ({data}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant='h5' component='h2'
        color='textPrimary' align='center'
        className={classes.title}
      >
        {data.title}
      </Typography>
      <Typography variant='body2' component='p'
        align='left' paragraph
        className={classes.description}
      >
        {data.description}
      </Typography>
      {data.link ? (
      <Typography variant='h4' component='p'
        align='left'
      >
        <a target="_blank" rel="norefferer"
          href={data.link}
          className={classes.link}
        >
          <GlitchText>
          website
          </GlitchText>
        </a>
      </Typography>
      ) : null}
      </>
  )
}  

export {Description, Picture}