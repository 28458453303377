import React from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Layout from '../../layouts/Website'
import Paper, { PaperClean } from '../../components/Paper'
import Heading from '../../components/Heading'

import { Picture, Description } from '../../views/web/Production'

const useStyles = makeStyles((theme) => ({

}));

const data = [
  {
    data: {
      title: 'skllchain.com',
      link: '',
      description: "SKLL Chain is a software production company which provides software engineering and consulting services. Specializes on project management platforms and optimized business solutions.",
    },
    images: [
      {
        src: require("../../assets/images/works/software_engineering.png").default,
        altText: "skllchain logo",
        caption: "SKLL Logo",
      }
    ]
  },
  {
    data: {
      title: 'skllmotorsport.com',
      link: '',
      description: "SKLL Motorsport is a drft team and motorsport software provider. Project under development.",
    },
    images: [
      {
        src: require("../../assets/images/works/motorsport.jpg").default,
        altText: "SKLL Motorsport caption",
        caption: "SKLL Motorsport",
      }
    ]
  },
]

const IndexPage = () => {
  const classes = useStyles();
  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
          <Heading title='Production' />
          </Paper>
        </Grid>

        {data.map((obj, index) => {
         return <Grid item xs={12} key={index}>
          <Paper>
            <Grid container>
              <Grid item xs={12} md={6}>
                <PaperClean>
                  <Picture data={obj.images}/>
                </PaperClean>
              </Grid>
              <Grid item xs={12} md={6}>
                <PaperClean>
                  <Description data={obj.data}/>
                </PaperClean>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        })}
    
    </Layout>  )
}

export default IndexPage
